//@ts-ignore
import i18nJs from "i18n-js";
// Please accept the invitation sent on ther mail box 
i18nJs.translations = {
  en: {
    "You can select a maximum of 3 answers" : "You can select a maximum of 3 answers",
    "Survey" : "Survey",
    "Submit rating" : "Submit rating",
    "No, thanks" : "No, thanks",
    "Type here" : "Type here" ,
    "Your payment proof has been submitted and is currently under review. You can track the status of your payment in Payment History" : "Your payment proof has been submitted and is currently under review. You can track the status of your payment in Payment History",
    "Your payment has been updated and is under review. Once confirmed your transaction will be completed" : "Your payment has been updated and is under review. Once confirmed your transaction will be completed",
   "Amount Paid:" : "Amount Paid:",
"Paid By:" :"Paid By:",
    "Payment Proof Submitted" : "Payment Proof Submitted",
    "Please accept the invitation sent on the mail box" : "Please accept the invitation sent on the mail box",
    "Something Went Wrong" : "Something Went Wrong",
    "emailError": "Email has already been taken",
  "phoneError": "Phone number has already been taken",
  "invalidPhoneError": "Phone number is invalid or unrecognized",
  "OTP is incorrect please check again" : "OTP is incorrect please check again",
    Tutorials: "Tutorials",
    Language: "Language",
    "Browse Course": "Browse Course",
    "My Courses": "My Courses",
    "My Course": "My Course",
    Assessment: "Assessment",
    "About Us": "About Us",
    Settings: "Settings",
    "User Profile": "User Profile",
    "Email Notification": "Email Notification",
    "Email Notifications": "Email Notifications",
    Payments: "Payments",
    "Payments History": "Payments History",
    "Contact Us": "Contact Us",
    Logout: "Logout",
    Student: "Student",
    Lecturer: "Lecturer",
    "No Courses Available": "No Courses Available",
    "No Courses": "No Courses",
    Completed: "Completed",
    Ongoing: "Ongoing",
    "Search here": "Search here",
    Search: "Search",
    "Upcoming Sessions": "Upcoming Sessions",
    "No Upcoming Sessions": "No Upcoming Sessions",
    "No image available": "No image available",
    "You are not able to access all lesson until you have purchased the selected course" : "You are not able to access all lesson until you have purchased the selected course",
    Sessions: "Sessions",
    "A Course by": "A Course by",
    Categories: "Categories",
    "Subjective Answer Marks" : "Subjective Answer Marks",
    "Sort by": "Sort by",
    "Oldest to Newest": "Oldest to Newest",
    "Newest to Oldest": "Newest to Oldest",
    "On-Campus Workshops": "On-Campus Workshops",
    "On-Campus": "On campus workshop",
    "Live Sessions": "Live Sessions",
    "Live Session": "Live Session",
    Online: "Online",
    "Online Courses": "Online Courses",
    "Continue course": "Continue course",
    "Start Course": "Start Course",
    "Enroll Now for at": "Enroll Now for at",
    "Advanced filters": "Advanced filters",
    "By Categories": "By Categories",
    "Select Categories": "Select Categorie",
    "By Language": "By Language",
    "Select Language": "Select Language",
    English: "English",
    Arabic: "Arabic",
    "By Type": "By Type",
    "By Price": "By Price",
    "By Level": "By Level",
    Level: "Level",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
    "Recent history": "Recent history",
    "Clear All": "Clear All",
    By: "By",
    About: "About",
    Lessons: "Lessons",
    mins: "mins",
    and: "and",
    hr: "hr",
    "No Course Selected": "No Course Selected",
    "No Assessments available": "No Assessments available",
    "No Assessments": "No Assessments",
    "Completed Assessments": "Completed Assessments",
    Submited: "Submitted",
    "View Result": "View Result",
    "Ongoing Assessments": "Ongoing Assessments",
    "Take Test": "Take Test",
    "Due on": "Due on",
    "Test Result": "Test Result",
    "The assessment results were updated successfully.":
      "The assessment results were updated successfully.",
    "Number of retake test limit exceeded.":
      "Number of retake test limit exceeded.",
    Grade: "Grade",
    "Pass/Fail": "Pass/Fail",
    pass: "Pass",
    fail: "fail",
    "Feedback by Lecturer": "Feedback by Lecturer",
    Feedback: "Feedback",
    Back: "Back",
    "Retake Test": "Retake Test",
    "Profile Picture": "Profile Picture",
    "Upload picture": "Upload picture",
    "Delete picture": "Delete picture",
    "Basic Information": "Basic Information",
    "First Name": "First Name",
    "Last Name": "Last Name",
    Email: "Email",
    "Phone Number": "Phone Number",
    "Date of birth": "Date of birth",
    Gender: "Gender",
    "Select Gender": "Select Gender",
    Male: "Male",
    Female: "Female",
    City: "City",
    Country: "Country",
    "Identification Card": "Identification Card",
    "Passport no": "Passport no",
    National: "National",
    "Resident ID": "Resident ID",
    Iqama: "Iqama",
    Account: "Account",
    "Change password": "Change password",
    "Change Password": "Change Password",
    "Edit Profile": "Edit Profile",
    "Save changes": "Save changes",
    "Account Delete": "Account Delete",
    "Are you sure want to delete this account":
      "Are you sure want to delete this account",
    "Cancel Delete": "Cancel Delete",
    "Delete Account": "Delete Account",
    Occupation: "Occupation",
    "Automatic Reminder": "Automatic Reminder",
    "Notification will be sent to your mail":
      "Notification will be sent to your mail",
    Ten: "Ten",
    Twenty: "Twenty",
    Thirty: "Thirty",
    All: "All",
    Declined: "Declined",
    Approved: "Approved",
    Pending: "Pending",
    Paid:"Paid",
    "Mail us on": "Mail us on",
    Address: "Address",
    "Get Directions": "Get Directions",
    "Frequently asked questions": "Frequently asked questions",
    "Are you sure want to Logout": "Are you sure want to Logout.",
    Cancel: "Cancel",
    "All Sessions": "All Sessions",
    "Start Learning": "Start Learning",
    "All lessons must be completed before taking the assessment":
      "All lessons must be completed before taking the assessment",
    "Assessment Test": "Assessment Test",
    "Lesson Description": "Lesson Description",
    "Lesson Attachment": "Lesson Attachment",
    "Write a Review": "Write a Review",
    "Submit Review": "Submit Review",
    "Submit Now": "Submit Now",
    Submit: "Submit",
    Review: "Review",
    "Write here": "Write here",
    Subtotal: "Subtotal",
    Tax: "Tax",
    "Basic Price": "Basic Price",
    "Verify the information before proceeding":
      "Verify the information before proceeding",
    "Please complete payment to use the features":
      "Please complete payment to use the features",
    Summary: "Summary",
    "Offline" : "Offline",
    "Pay Offline": "Pay Offline",
    "Pay by Tabby": "Pay by Tabby",
    "Credit/Debit": "Credit/Debit",
    "Bank Details": "Bank Details",
    "Attachment needed": "Attachment needed",
    "Upload here": "Upload here",
    Cart: "Cart",
    Remove: "Remove",
    "Click on below mentioned link": "Click on below mentioned link",
    "Go to Tabby": "Go to Tabby",
    "Card Number": "Card Number",
    "How the platform works": "How the platform works",
    "Found useful": "Found useful",
    "Rate us based on experience": "Rate us based on experience",
    "Review Submitted Successfully": "Review Submitted Successfully",
    "Please Fill The Review": "Please Fill The Review",
    "Upload Profile Image": "Upload Profile Image",
    "Upload the profile image from your file manager to show facial recognition":
      "Upload the profile image from your file manager to show facial recognition",
    "Click to upload or drag & drop Maximum file size 5 MB":
      "Click to upload or drag & drop Maximum file size 5 MB.",
    "Image is Required": "Image is Required",
    "Sorry, an error has occurred while uploading 'Raffels image'. Please try again":
      "Sorry, an error has occurred while uploading 'Raffels image'. Please try again.",
    "Sorry, an error has occurred while uploading 'Raffles Video'. Please try again.":
      "Sorry, an error has occurred while uploading 'Raffles Video'. Please try again.",
    Discard: "Discard",
    "Upload now": "Upload now",
    "No Data": "No Data",
    "No data to display!": "No data to display!",
    "Create Assessment test": "Create Assessment test",
    "Assessment test Results": "Assessment test Results",
    "Assessment test": "Assessment test",
    // Toast
    "Change password successfully.":"Change password successfully.",
    "Image deleted Successfully": "Image deleted Successfully.",
    "Network issue detected. Please check your connection and try again.":
      "Network issue detected. Please check your connection and try again.",
    "Profile updated successfully": "Profile updated successfully.",
    "Assessment Deleted successfully": "Assessment Deleted successfully.",
    "No course record available for the lecturer":
      "No course record available for the lecturer.",
    "Assessment created successfully": "Assessment created successfully.",
    "Assessment updated successfully": "Assessment updated successfully.",
    "Review submitted successfully": "Review submitted successfully.",
    "Please fill the review": "Please fill the review",
    "Please try again later": "Please try again later",
    "Course removed successfully": "Course removed successfully.",
    "Course uploaded successfully": "Course uploaded successfully.",
    "Course is successfully updated": "Course is successfully updated.",
    "Session deleted successfully": "Session deleted successfully.",
    "Session created successfully": "Session created successfully",
    "Zoom user not found" : "Zoom user not found",
    "Session updated successfully": "Session updated successfully.",
    "Please attach the payment document before submitting":
      "Please attach the payment document before submitting.",
    "Technical Difficulties":
      "We're experiencing technical difficulties at the moment. Please try again later.",
    //Errors
    "No account registered with this email, or account not activated":"No account registered with this email, or account not activated",
    "Login Failed, Incorrect Password":"Login Failed, Incorrect Password.",
    "Wrong Role Selected":"Wrong Role Selected.",
    "We have sent you a rest password link into your registered Email ID":"We have sent you a rest password link into your registered Email ID",
    "Email has already been taken":"Email has already been taken",
    "Phone number has already been taken":"Phone number has already been taken",
    "Phone number Invalid or Unrecognized Phone Number":"Phone number Invalid or Unrecognized Phone Number",
    "Email address not found. Please check and try again":"Email address not found. Please check and try again",
    //Tabby response
    "Payment was successful": "Payment was successful",
    "Payment was failed": "Payment was failed",
    "Payment was canceled": "Payment was canceled",
    "Your payment has been completed": "Your payment has been completed.",
    "Your payment has been failed": "Your payment has been failed.",
    "Your payment has been canceled": "Your payment has been canceled.",
    "Amount Paid": "Amount Paid",
    "Paid By": "Paid By",
    "Tabby Payments": "Tabby Payments",
    "Credit/debit card" : "Credit/debit card",
    "Go to Payment History": "Go to Payment History",
    SELECT_ROLE: "Select Role",
    SELECT_ROLE_AS_PER_YOUR_PROFILE: "Select role as per your profile",
    AS: "As",
    STUDENT: "Student",
    LECTURER: "Lecturer",
    START_NOW: "Start Now",
    //////////
    WELCOME: "Welcome!",
    SIGN_IN_TO_RAFFLES: "Sign in to Raffles",
    LOGIN_ERROR:"No account registered with this email, or account not activated",
    PROFILE_UPDATED: "Profile Updated",
    "The_required_user_profile_details_were_successfully_updated":"The required user profile details were successfully updated.",
    YOUR_EMAIL: "Your Email",
    PASSWORD: "Password",
    FORGOT_PASSWORD: "Forgot Password?",
    REMEMBER_ME: "Remember Me",
    LOGIN: "Login",
    OR: "Or",
    SIGN_UP: "Sign Up",
    /////////
    EMAIL_IS_REQUIRED: "Email is required.",
    INVALID_EMAIL_ADDRESS: "Invalid email address.",
    PLEASE_ENTER_VALID_EMAIL_ADDRESS: "Please enter valid email address.",
    PASSWORD_IS_REQUIRED: "Password is required.",
    PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS:
      "Password must be at least 8 characters.",
    NEW_PASSWORD_IS_REQUIRED: "New Password is required.",
    OLD_PASSWORD_IS_REQUIRED: "Old Password is required.",
    Wrong_Old_Password:"Wrong Old Password.",
    CONFIRM_PASSWORD_IS_REQUIRED: "Confirm password is required.",
    PASSWORDS_MUST_MATCH: "Passwords must match.",
    FIRST_NAME_IS_REQUIRED: "First name is required.",
    PLEASE_ENTER_VALID_NAME: "Please enter valid name.",
    LAST_NAME_IS_REQUIRED: "Last name is required.",
    PLEASE_ENTER_VALID_LAST_NAME: "Please enter valid last name.",
    PLEASE_ENTER_COUNTRY_CODE: "Please enter country code",
    MINUMIN_NUMBER_LENGTH: "minumin number length should be 2 i.e. 91",
    MAXIMUM_NUMBER_LENGTH: "maximum number length should be 4 i.e. +971",
    NUMBER_IS_REQUIRED: "Number is required.",
    PLEASE_ENTER_VALID_NUMBER: "Please enter valid number.",
    MOBILE_NUMBER_SHOULD_CONTAIN_MINIMUM_7_DIGITS:
      "Mobile number should contain minimum 7 digits.",
    DOB_IS_REQUIRED: "DOB is required.",
    GENDER_IS_REQUIRED: "Gender is required.",
    COUNTRY_IS_REQUIRED: "Country is required",
    PLEASE_ENTER_VALID_COUNTRY: "Please enter valid country.",
    CITY_IS_REQUIRED: "City is required.",
    PLEASE_ENTER_VALID_CITY: "Please enter valid city.",
    OCCUPATION_IS_REQUIRED: "Occupation is required.",
    PLEASE_ENTER_VALID_OCCUPATION: "Please enter valid occupation.",
    IMAGE_IS_REQUIRED: "Image is required.",
    OFFICIAL_EMAIL_IS_REQUIRED: "Official email is required.",
    PLEASE_ENTER_VALID_QUALIFICATION: "Please enter valid Qualification.",
    QUALIFICATION_IS_REQUIRED: "Qualification is required.",
    PERSONAL_PHOTO_IS_REQUIRED: "Personal photo is required.",
    FILE_SIZE_IS_TOO_LARGE: "File size is too large.",
    IDENTIFICATION_CARD_IS_REQUIRED: "Identification card is required.",
    RESUME_IS_REQUIRED: "Resume is required.",
    DEPARTMENT_IS_REQUIRED: "Department is required.",
    PLEASE_ENTER_VALID_DEPARTMENT: "Please enter valid department.",
    MAJOR_IS_REQUIRED: "Major is required.",
    PLEASE_ENTER_VALID_MAJOR: "Please enter valid major.",
    EXPERIENCE_IS_REQUIRED: "Experience is required.",
    PLEASE_ENTER_VALID_EXPERIENCE: "Please enter valid experience.",
    BIOGRAPHY_IS_REQUIRED: "Biography is required.",
    PLEASE_ENTER_VALID_BIOGRAPHY: "Please enter valid biography.",
    
    ////////
    SIGN_UP_FOR_AN_ACCOUNT: "Sign up for an account",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email*",
    PHONE_NUMBER: "Phone Number*",
    CODE: "Code*",
    PASSWORD_: "Password*",
    CONFIRM_PASSWORD: "Confirm Password",
    DATE_OF_BIRTH: "Date of Birth*",
    GENDER: "Gender*",
    SELECT_GENDER: "--Select Gender--",
    MALE: "Male",
    FEMALE: "Female",
    COUNTRY: "Country*",
    CITY: "City*",
    MUMBAI: "Mumbai",
    INDIA: "India",
    IDENTIFICATION_CARD: "Identification Card*",
    PASSPORT_NO_NATIONAL_RESIDENT_ID_LQAMA:
      "(Passport no., National & Resident ID - lqama)*",
    UPLOAD_HERE: "Upload here",
    OCCUPATION: "Occupation*",
    DEVELOPER: "Developer",
    OFFICIAL_EMAIL: "Official Email*",
    QUALIFICATION: "Qualification",
    TYPE_HERE: "Type here",
    PERSONAL_PHOTO: "Personal Photo",
    RESUME: "Resume",
    DEPARTMENT: "Department",
    MAJORS: "Major/s",
    EXPERIENCE: "Experience",
    BIOGRAPHY: "Biography",
    /////////////FORGOTPASSWORD///////
    NO_WORRIES_WE_LL_SEND_YOU_RESET_INSTRUCTIONS:
      "No worries, we'll send you reset instructions.",
    RESET_PASSWORD: "Reset Password",
    PASSWORD_RESET: "Password Reset",
    WE_SENT_A_CODE_TO_RAFFLES: "We sent a code to raffles@gmail.com",
    CONTINUE: "Continue",
    CREATE_NEW_PASSWORD: "Create New Password",
    MUST_BE_AT_LEAST_8_CHARACTERS: "Must be at least 8 characters.",
    ALL_SET_NOW: "All Set Now!",
    YOUR_PASSWORD_HAS_BEEN_RESET_NOW: "Your password has been reset now.",
    ///////
    VERIFY_MOBILE_NUMBER: "Verify Mobile Number",
    ENTER_THE_CODE_JUST_SENT_TO: "Enter the code just sent to:",
    ENTER_CODE: "Enter Code",
    DIDNT_GET_CODE: "Didn't get code?",
    SEND_AGAIN: "Send again",
    CALL_ME_INSTEAD: "Call me instead",
    VERIFY_MOBILE_NUMBER_BUTTON: "Verify mobile number",
    ////////
    TERMS_CONDITIONS: "Terms & Conditions",
    AGREEMENTS: "Agreements",
    I_AGREE: "I Agree",
    DECLINE: "Decline",
    PRIVACY_POLICY: "Privacy Policy",
    UPDATED_ON: "Updated on 25 May 2023",
    UNDERSTOOD: "Understood",
    ///////LANDING PAGE///////////
    ABOUT_US: "About Us",
    COURSES: "Courses",
    CONTACT_US: "Contact Us",
    SIGN_IN: "Sign in",
    CREATE_NEW_ACCOUNT: "Create new account",
    EXPLORE: "Explore",
    OUR_ONLINE_COURSES_TO: "our online courses to",
    ENHANCE: "enhance",
    YOUR: "your",
    SKILLS: "skills",
    AND: "and",
    EXPERTISE: "expertise",
    DESIGN_YOUR_FUTURE_WITH_RAFFLES: "Design your future with Raffles.",
    GET_STARTED: "Get Started",
    SEE_HOW_IT_WORKS: "See how it works",
    PUBLIC_SPEAKING: "Public Speaking",
    CAREER_ORIENTED: "Career-Oriented",
    CREATIVE_THINKING: "Creative Thinking",
    STUDENTS_LEARNING: "students learning",
    ONLINE_COURSES: "Online Courses",
    TUTORS: "Tutors",
    VIDEOS: "Videos",
    TRUSTED_BY_TOP_100_COMPANIES: "Trusted by top 100+ Companies",
    EXPLORE_PROGRAMS: "Explore Programs",
    OUR_MOST_POPULAR_CLASSES: "Our Most Popular Classes",
    BROWSE_COURSES: "Browse Courses",
    ///////////
    RAFFLES_EDUCATION_INSTITUTE_ETC: `Raffles Education Institute, established in 2014, is one of
                                      a chain of thirty branches of Raffles Institutes which was
                                      established in 1990 in Singapore, and one of the first
                                      private institutions to offer courses in Fashion Design.`,
    WITHIN_5_YEARS_ETC: `Within 5 years, multiple majors were offered at Raffles
                          Institute, including (graphic design — product design —
                          jewelry design — interior design — game design — animation
                          design).`,
    IN_2004_RAFFLES_DESIGN_ETC: `In 2004, Raffles Design Institute was awarded the
                                  Singapore Quality Readiness for Special Education
                                  Organizations. In 2005, Raffles was awarded the CaseTrust
                                  Award for Education.In 2008, Raffles Design Institute was
                                  recognized by Edupoll as one of the top ten educational
                                  institutions in Singapore and the largest provider of
                                  private education globally.`,
    OUR_SERVICES: "Our Services",
    DESIGN_YOUR_FUTURE_WITH_OUR_GLOBAL_NETWORK:
      "Design your future with our global network of award-winninginstitutions",
    USER_EXPERIENCE: "User Experience",
    LESSONS_ON_DESIGN_THAT_COVER:
      "Lessons on design that cover the most recent developments.",
    LEARN_MORE: "Learn More",
    MARKETING: "Marketing",
    MARKETING_COURSES_THAT_COVER:
      "Marketing courses that cover the most recent marketing trends",
    WEB_DEVELOPMENT: "Web Development",
    CLASSES_IN_DEVELOPMENT_THAT_COVER:
      "Classes in development that cover the most recent advancements in web.",
    COURSES_WERE_EXCELLENT: `Courses were excellent! For individuals who need a refresher
                  course or are looking to start a new career, it is the
                  Master platform.`,
    RAFFLES_JR: "Raffles Jr.",
    STUDENT_DUBAI_NATIONAL_UNIVERSITY: "Student, Dubai National University",
    TOP_LEARNING_EXPERIENCES_THAT_CREATE:
      "Top learning experiences that create",
    MORE_TALENT_IN_THE_WORLD: "more talent in the world.",
    EASTERN_TAM_KEEN_ALL_RIGHTS_RESERVED:
      "2023 Eastern tam keen. All rights reserved.",
    //Lecturer
    "Create New Course": "Create New Course",
    Analytics: "Analytics",
    Edit: "Edit",
    "Schedule Live Sessions": "Schedule Live Sessions",
    "Scheduled Session": "Scheduled Session",
    "Schedule new Session": "Schedule new Session",
    "Join Now": "Join Now",
    "No Live Session": "No Live Session",
    "Create New Assessment": "Create New Assessment",
    "No Assessment": "No Assessment",
    Questions: "Questions",
    "Create Assessment": "Create Assessment",
    "Course Name": "Course Name",
    "Assessment Name": "Assessment Name",
    "Number of Retake Test": "Number of Retake Test",
    "Passing Score": "Passing Score* (within 100%)",
    "Select Course": "Select Course",
    Marks: "Marks",
    "Max Marks" : "Max Marks",
    "Student Answer" : "Student Answer",
    "Correct Answer" : "Correct Answer",
    "Multiple Choice Answer": "Multiple Choice Answer",
    "Single Choice Answer": "Single Choice Answer",
    "DropDown Answer": "DropDown Answer",
    "Subjective Answer": "Subjective Answer",
    "Add Questions": "Add Questions",
    "Submit Assessment": "Submit Assessment",
    "Assessment test results": "Assessment test results",
    Test: "Test",
    "Test Date": "Test Date",
    Range: "Range",
    "S No": "S No.",
    "Submit Result": "Submit Result",
    "Official Email": "Official Email",
    "New Password": "New Password",
    "Old Password": "Old Password",
    "Confirm Password": "Confirm Password",
    "Password Changed": "Password Changed",
    "Your password has been successfully changed":
      "Your password has been successfully changed.",
    "Email notification": "Email notification",
    enabled: "enabled",
    disabled: "disabled",
    "Back to Home": "Back to Home",
    "Live Sessions Overview": "Live Sessions Overview",
    "Number of Sessions": "Number of Sessions",
    "Date Range": "Date Range",
    "Sessions Attended": "Sessions Attended",
    "Student Name": "Student Name",
    "New Users": "New Users",
    "Regular Users": "Regular Users",
    "Number of Students": "Number of Students",
    Dates: "Dates",
    "Participation Analysis": "Participation Analysis",
    Attended: "Attended",
    Users: "Users",
    "Not Attended": "Not Attended",
    "Participant Attendance": "Participant Attendance",
    "Search for participant here": "Search for participant here",
    Date: "Date",
    Time: "Time",
    "Course Banner/Image": "Course Banner/Image",
    Information: "Information",
    Title: "Title",
    "Course Category": "Course Category",
    Description: "Description",
    Price: "Price",
    "Select Level": "Select Level",
    "Course Type": "Course Type",
    "No of Lessons": "No of Lessons",
    "Total Duration of Lessons": "Total Duration of Lessons",
    "Lesson Video": "Lesson Video",
    "Lesson Attachments": "Lesson Attachments",
    "Add Lesson": "Add Lesson",
    "Save Course": "Save Course",
    "Course Delete": "Course Delete",
    "Are you sure want to delete this Course.":
      "Are you sure you want to delete this Course?",
    "Delete Course": "Delete Course",
    Placeholder: "Placeholder",
    "Type Price": "Type Price",
    Type: "Type",
    "Type Address here": "Type Address here",
    "Click to upload or drag & drop": "Click to upload or drag & drop",
    "Maximum file size 100 MB.": "Maximum file size 100 MB.",
    uploaded: "uploaded",
    typeLessonName: "Type Lesson Name",
    nameIsRequired: "Name is required.",
    titleIsRequired: "Title is required.",
    pleaseSelectCourseCategory: "Please select course category.",
    pleaseSelectCourseType: "Please select course type.",
    pleaseSelectLevel: "Please select level.",
    pleaseSelectLanguage: "Please select language.",
    informationRequired: "Information is required.",
    courseBannerRequired: "Course banner/image is required.",
    descriptionRequired: "Description is required.",
    validPriceRequired: "Please enter a valid price.",
    noOfLessonsRequired: "No of lessons is required.",
    validNumberRequired: "Please enter a valid number.",
    validDuration: "Please enter a valid duration.",
    requiredDuration: "No of duration is required.",
    requiredVideo: "Video is required.",
    requiredAttachments: "Attachments are required.",
    requiredDescription: "Description is required.",
    createCourse: "Create Course",
    selectCourseCategory: "Select Course Category",
    uploadCourse: "Upload Course",
    uploadLessonVideos:
      "Upload the Lesson videos from your file manager to show facial recognition",
    upload: "Upload",
    scheduleSession: "Schedule Session",
    subject: "Subject",
    course: "Course",
    to: "to",
    showLiveLocationAddress: "Show Live Location Address",
    showToStudent: "Show to student",
    saveSession: "Save Session",
    Delete: "Delete",
    sessionDelete: "Session Delete",
    confirmSessionDelete: "Are you sure want to delete this Session.",
    deleteSession: "Delete Session",
    dateRequired: "Date is required.",
    dateInFuture: "Date should be in the future.",
    startTimeRequired: "Start time is required.",
    subjectRequired: "Subject is required.",
    locationRequired: "Location is required.",
    courseRequired: "Course is required.",
    endTimeRequired: "End time is required.",
    endTimeGreaterThanStart: "End time must be greater than start time.",
    "Edit Assessment": "Edit Assessment",
    saveAssessment: "Save Assessment",
    assessmentDelete: "Delete Assessment",
    confirmDeleteAssessment: "Are you sure you want to delete this Assessment?",
    courseNameRequired: "Course name is required.",
    scoreRequired: "Score is required.",
    passingScoreValidation: "Passing score should be less than 100.",
    retakeTestRequired: "Retake test is required.",
    marksRequired: "Marks are required.",
    textRequired: "Text is required.",
    correctAnswerRequired: "Correct answer is required.",
    correctAnswerInvalid: "This Correct Answer is invalid because it does not match any of the provided options above.",
    correctAnswerMatch: "Correct answer should exactly match with any one of the answer options provided.",
    answerRequired: "Answer is required.",
    addFourOptions: "Please add four different answer options.",
    live: "Live",
    Upcoming: "Upcoming",
    Duration: "Duration",
    dollar : "$",
    hour : "hr",
    min : "mins",
    Enrolled : "Enrolled",
    "Assessment submitted successfully" : "Assessment submitted successfully",
    "Results will be available after manual grading by the lecturer" : "Results will be available after manual grading by the lecturer",
    "Assessment submitted. Results will be available after manual grading by the lecturer" : "Assessment submitted. Results will be available after manual grading by the lecturer",
  },
  ar: {
    "You can select a maximum of 3 answers" : "يمكنك اختيار ما يصل إلى 3 إجابات كحد أقصى",
    "Survey" : "استبيان",
    "Submit rating" : "إرسال التقييم",
    "No, thanks" : "لا، شكرًا",
    "Type here" : "اكتب هنا",
    "Your payment proof has been submitted and is currently under review. You can track the status of your payment in Payment History" :  "تم تقديم إثبات الدفع الخاص بك وهو حاليًا قيد المراجعة. يمكنك تتبع حالة الدفع الخاصة بك في سجل المدفوعات.",
    "Your payment has been updated and is under review. Once confirmed your transaction will be completed" : "تم تحديث دفعتك وهي قيد المراجعة حاليًا. بمجرد التأكيد، سيتم إكمال معاملتك.",
    "Amount Paid:" : "المبلغ المدفوع:",
    "Paid By:" : "دفع بواسطة:",
    "Payment Proof Submitted" : "تم تقديم إثبات الدفع.",
    "Please accept the invitation sent on the mail box" : "يرجى قبول الدعوة المرسلة إلى صندوق البريد.",
    "Something Went Wrong" : "حدث خطأ ما",
    "emailError": "تم بالفعل أخذ البريد الإلكتروني",
  "phoneError": "تم أخذ رقم الهاتف بالفعل",
  "invalidPhoneError": "رقم الهاتف غير صالح أو رقم الهاتف غير المعترف به",
  "OTP is incorrect please check again" : "رمز التحقق غير صحيح، يرجى التحقق مرة أخرى",
    Tutorials: "دروس",
    Language: "لغة",
    "Browse Course": "تصفح الدورة",
    "My Courses": "دوراتي",
    "My Course": "دورتي",
    Assessment: "تقييم",
    "About Us": "معلومات عنا",
    Settings: "الإعدادات",
    "User Profile": "الملف الشخصي",
    "Email Notification": "إشعارات البريد الإلكتروني",
    "Email Notifications": "إشعارات البريد الإلكتروني",
    Payments: "المدفوعات",
    "Payments History": "تاريخ المدفوعات",
    "Contact Us": "اتصل بنا",
    Logout: "تسجيل الخروج",
    Student: "طالب",
    Lecturer: "محاضر",
    "No Courses Available": "لا توجد دورات متاحة",
    "No Courses": "لا توجد دورات",
    Completed: "مكتمل",
    Ongoing: "مستمر",
    "Search here": "ابحث هنا",
    Search: "بحث",
    "Upcoming Sessions": "الجلسات القادمة",
    "No Upcoming Sessions": "لا توجد جلسات قادمة",
    "No image available": "لا توجد صورة متاحة",
    "You are not able to access all lesson until you have purchased the selected course" : "أنت غير قادر على الوصول إلى جميع الدروس حتى تشتري الدورة المحددة",
    Sessions: "جلسات",
    "A Course by": "دورة من",
    Categories: "الفئات",
    "Subjective Answer Marks" : "درجات الإجابة الذاتية",
    "Sort by": "ترتيب حسب",
    "Oldest to Newest": "الأقدم إلى الأحدث",
    "Newest to Oldest": "الأحدث إلى الأقدم",
    "On-Campus Workshops": "ورش العمل في الحرم الجامعي",
    "On-Campus": "ورشة عمل في الحرم الجامعي",
    "Live Sessions": "جلسات مباشرة",
    "Live Session": "جلسة مباشرة",
    Online: "عبر الإنترنت",
    "Online Courses": "الدورات عبر الإنترنت",
    "Continue course": "متابعة الدورة",
    "Start Course": "ابدأ الدورة",
    "Enroll Now for at": "سجل الآن مقابل",
    "Advanced filters": "الفلاتر المتقدمة",
    "By Categories": "حسب الفئات",
    "Select Categories": "اختر الفئة",
    "By Language": "حسب اللغة",
    "Select Language": "اختر اللغة",
    English: "الإنجليزية",
    Arabic:"العربية",
    "By Type": "حسب النوع",
    "By Price": "حسب السعر",
    "By Level": "حسب المستوى",
    Level: "مستوى",
    Beginner: "مبتدئ",
    Intermediate: "متوسط",
    Advanced: "متقدم",
    "Recent history": "التاريخ الحديث",
    "Clear All": "مسح الكل",
    By: "بواسطة",
    About: "حول",
    Lessons: "الدروس",
    mins: "دقائق",
    and: "و",
    hr: "ساعة",
    "No Course Selected": "لم يتم اختيار دورة",
    "No Assessments available": "لا توجد تقييمات متاحة",
    "No Assessments": "لا توجد تقييمات",
    "Completed Assessments": "التقييمات المكتملة",
    Submited: "تم تقديمه",
    "View Result": "عرض النتيجة",
    "Ongoing Assessments": "التقييمات الجارية",
    "Take Test": "اخذ الاختبار",
    "Due on": "مستحق في",
    "Test Result": "نتيجة الاختبار",
    "The assessment results were updated successfully.":
      "تم تحديث نتائج التقييم بنجاح.",
    "Number of retake test limit exceeded.":
      "تم تجاوز حد عدد الاختبارات المعادة.",
    Grade: "الدرجة",
    "Pass/Fail": "نجاح/رسوب",
    pass: "نجاح",
    fail: "فشل",
    "Feedback by Lecturer": "التعليقات من المحاضر",
    Feedback: "ملاحظات",
    Back: "عودة",
    "Retake Test": "إعادة الاختبار",
    "Profile Picture": "صورة الملف الشخصي",
    "Upload picture": "تحميل الصورة",
    "Delete picture": "حذف الصورة",
    "Basic Information": "المعلومات الأساسية",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    Email: "البريد الإلكتروني",
    "Phone Number": "رقم الهاتف",
    "Date of birth": "تاريخ الميلاد",
    Gender: "الجنس",
    "Select Gender": "اختر الجنس",
    Male: "ذكر",
    Female: "أنثى",
    City: "مدينة",
    Country: "بلد",
    "Identification Card": "بطاقة الهوية",
    "Passport no": "رقم الجواز",
    National: "وطني",
    "Resident ID": "رقم الهوية السكنية",
    Iqama: "إقامة",
    Account: "الحساب",
    "Change password": "تغيير كلمة المرور",
    "Change Password": "تغيير كلمة المرور",
    "Edit Profile": "تعديل الملف الشخصي",
    "Save changes": "حفظ التغييرات",
    "Account Delete": "حذف الحساب",
    "Are you sure want to delete this account":
      "هل أنت متأكد أنك تريد حذف هذا الحساب؟",
    "Cancel Delete": "إلغاء الحذف",
    "Delete Account": "حذف الحساب",
    Occupation: "الوظيفة",
    "Automatic Reminder": "التذكير التلقائي",
    "Notification will be sent to your mail":
      "سيتم إرسال الإشعار إلى بريدك الإلكتروني",
    Ten: "عشرة",
    Twenty: "عشرون",
    Thirty: "ثلاثون",
    All: "الكل",
    Declined: "مرفوض",
    Approved: "موافق",
    Pending: "قيد المراجعة",
    Paid:"مدفوع",
    "Mail us on": "راسلنا على",
    Address: "عنوان",
    "Get Directions": "احصل على الاتجاهات",
    "Frequently asked questions": "الأسئلة الشائعة",
    "Are you sure want to Logout": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    Cancel: "إلغاء",
    "All Sessions": "جميع الجلسات",
    "Start Learning": "ابدأ التعلم",
    "All lessons must be completed before taking the assessment":
      "يجب إكمال جميع الدروس قبل إجراء التقييم",
    "Assessment Test": "اختبار التقييم",
    "Lesson Description": "وصف الدرس",
    "Lesson Attachment": "مرفق الدرس",
    "Write a Review": "اكتب مراجعة",
    "Submit Review": "إرسال المراجعة",
    "Submit Now": "قدم الآن",
    Submit: "تاكيد",
    Review: "مراجعة",
    "Write here": "اكتب هنا",
    Subtotal: "المجموع الفرعي",
    Tax: "ضريبة",
    "Basic Price": "السعر الأساسي",
    "Verify the information before proceeding":
      "تحقق من المعلومات قبل المتابعة",
    "Please complete payment to use the features":
      "يرجى إتمام الدفع لاستخدام الميزات",
    Summary: "الملخص",
    "Offline" : "غير متصل",
    "Pay Offline": "الدفع غير المتصل",
    "Pay by Tabby": "الدفع عن طريق تابى",
    "Credit/debit card" : "بطاقة ائتمان/خصم",
    "Credit/Debit": "بطاقة ائتمان/خصم",
    "Bank Details": "تفاصيل البنك",
    "Attachment needed": "المرفق مطلوب",
    "Upload here": "قم برفع هنا",
    Cart: "عربة التسوق",
    Remove: "إزالة",
    "Go to Tabby": "اذهب إلى تابى",
    "Card Number": "رقم البطاقة",
    "Cardholder Name": "اسم حامل البطاقة",
    "Expiry Date": "تاريخ انتهاء الصلاحية",
    CVV: "رمز الأمان",
    "How the platform works": "كيف تعمل المنصة",
    "Found useful": "وجدت مفيدة؟",
    "Rate us based on experience": "قيمنا على أساس الخبرة",
    "Review Submitted Successfully": "تم تقديم المراجعة بنجاح",
    "Please Fill The Review": "يرجى ملء المراجعة",
    "Upload Profile Image": "رفع صورة الملف الشخصي",
    "Upload the profile image from your file manager to show facial recognition":
      "قم بتحميل صورة الملف الشخصي من مدير الملفات لعرض التعرف على الوجه",
    "Click to upload or drag & drop Maximum file size 5 MB":
      "انقر للتحميل أو اسحب وأفلت، الحد الأقصى لحجم الملف 5 ميغابايت",
    "Image is Required": "الصورة مطلوبة",
    "Sorry, an error has occurred while uploading 'Raffels image'. Please try again":
      "عذرًا، حدث خطأ أثناء تحميل 'صورة رافلز'. يرجى المحاولة مرة أخرى",
    "Sorry, an error has occurred while uploading 'Raffles Video'. Please try again":
      "عذرًا، حدث خطأ أثناء تحميل 'فيديو رافلز'. الرجاء المحاولة مرة أخرى.",
    Discard: "تجاهل",
    "Upload now": "ارفع الآن",
    "No Data": "لا توجد بيانات",
    "No data to display!": "لا توجد بيانات للعرض!",
    "Create Assessment test": "إنشاء اختبار التقييم",
    "Assessment test Results": "نتائج اختبار التقييم",
    "Assessment test": "اختبار التقييم",
    // Toast
   "Change password successfully.": "تم تغيير كلمة المرور بنجاح",
    "Image deleted Successfully": "تم حذف الصورة بنجاح",
    "Network issue detected. Please check your connection and try again.":
      "تم اكتشاف مشكلة في الشبكة. يرجى التحقق من اتصالك والمحاولة مرة أخرى",
    "Profile updated successfully": "تم تحديث الملف الشخصي بنجاح",
    "Assessment Deleted successfully": "تم حذف التقييم بنجاح",
    "No course record available for the lecturer":
      "لا يوجد سجل دورات متاح للمدرس",
    "Assessment created successfully": "تم إنشاء التقييم بنجاح",
    "Assessment updated successfully": "تم تحديث التقييم بنجاح",
    "Review submitted successfully": "تم تقديم المراجعة بنجاح",
    "Please fill the review": "يرجى ملء المراجعة",
    "Please try again later": "يرجى المحاولة مرة أخرى لاحقًا",
    "Course removed successfully": "تمت إزالة الدورة بنجاح",
    "Course uploaded successfully": "تم رفع الدورة بنجاح",
    "Course is successfully updated": "تم تحديث الدورة بنجاح",
    "Session deleted successfully": "تم حذف الجلسة بنجاح",
    "Session created successfully": "تم إنشاء الجلسة بنجاح",
    "Zoom user not found" : "لم يتم العثور على مستخدم Zoom." ,
    "Session updated successfully": "تم تحديث الجلسة بنجاح",
    "Please attach the payment document before submitting":
      "يرجى إرفاق وثيقة الدفع قبل الإرسال",
    "Technical Difficulties":
      "نحن نواجه صعوبات تقنية في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا",
      "Login Failed, Incorrect Password.":"فشل تسجيل الدخول، كلمة المرور غير صحيحة.yyyy",
    //Errors
    "No account registered with this email, or account not activated":"لم يتم تسجيل أي حساب بهذا البريد الإلكتروني، أو لم يتم تفعيل الحساب.",
    "Login Failed, Incorrect Password":"فشل تسجيل الدخول، كلمة المرور غير صحيحة",
    "Wrong Role Selected":"تم اختيار الدور الخاطئ.",
    "We have sent you a rest password link into your registered Email ID":"لقد أرسلنا لك رابط استعادة كلمة المرور إلى معرف البريد الإلكتروني المسجل لديك.",
    "Email has already been taken":"تم بالفعل أخذ البريد الإلكتروني",
    "Phone number has already been taken":"تم أخذ رقم الهاتف بالفعل",
    "Phone number Invalid or Unrecognized Phone Number":"رقم الهاتف غير صالح أو رقم هاتف غير معروف",
    "Email address not found. Please check and try again":"العنوان البريدي غير موجود. يرجى التحقق والمحاولة مرة أخرى.",

    LOGIN_ERROR:"لا يوجد حساب مسجل بهذا البريد الإلكتروني، أو الحساب غير مفعل",
    PROFILE_UPDATED:"تم تحديث الملف الشخصي",
    "The_required_user_profile_details_were_successfully_updated":"تم تحديث تفاصيل ملف تعريف المستخدم المطلوبة بنجاح.",
    //Tabby response
    "Payment was successful": "تمت عملية الدفع بنجاح",
    "Payment was failed": "فشلت عملية الدفع",
    "Payment was canceled": "تم إلغاء الدفع",
    "Your payment has been completed": "تمت عملية الدفع بنجاح",
    "Your payment has been failed": "فشلت عملية الدفع",
    "Your payment has been canceled": "تم إلغاء الدفع",
    "Amount Paid": "المبلغ المدفوع",
    "Paid By": "مدفوع بواسطة",
    "Tabby Payments": "مدفوعات تابى",
    "Go to Payment History": "اذهب إلى سجل المدفوعات",
    SELECT_ROLE: "حدد الدور",
    SELECT_ROLE_AS_PER_YOUR_PROFILE: "حدد الدور وفقًا لملفك الشخصي",
    AS: "كا",
    STUDENT: "طالب",
    LECTURER: "محاضر",
    START_NOW: "ابداء الان",
    ////////////
    WELCOME: "مرحباً!",
    SIGN_IN_TO_RAFFLES: "قم بتسجيل الدخول إلى حسابك",
    YOUR_EMAIL: "البريد الإلكتروني",
    PASSWORD: "كلمة المرور",
    FORGOT_PASSWORD: "هل نسيت كلمة السر؟",
    REMEMBER_ME: "تذكرنى",
    LOGIN: "تسجيل الدخول",
    OR: "أو",
    SIGN_UP: "اشتراك",
    ///////
    EMAIL_IS_REQUIRED: "البريد الإلكتروني مطلوب.",
    INVALID_EMAIL_ADDRESS: "عنوان البريد الإلكتروني غير صالح.",
    PLEASE_ENTER_VALID_EMAIL_ADDRESS: "الرجاء إدخال عنوان بريد إلكتروني صحيح.",
    PASSWORD_IS_REQUIRED: "كلمة المرور مطلوبة.",
    PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS:
      "يجب ألا تقل كلمة المرور عن 8 أحرف.",
    NEW_PASSWORD_IS_REQUIRED: "كلمة المرور الجديدة مطلوبة.",
    OLD_PASSWORD_IS_REQUIRED: "يجب إدخال كلمة المرور القديمة.",
    Wrong_Old_Password:"كلمة المرور القديمة خاطئة.",
    CONFIRM_PASSWORD_IS_REQUIRED: "مطلوب تأكيد كلمة المرور.",
    PASSWORDS_MUST_MATCH: "يجب أن تتطابق كلمات المرور.",
    FIRST_NAME_IS_REQUIRED: "الاسم الأول مطلوب.",
    PLEASE_ENTER_VALID_NAME: "الرجاء إدخال اسم صحيح.",
    LAST_NAME_IS_REQUIRED: "اسم العائلة مطلوب.",
    PLEASE_ENTER_VALID_LAST_NAME: "الرجاء إدخال اسم العائلة الصحيح.",
    PLEASE_ENTER_COUNTRY_CODE: "الرجاء إدخال رمز البلد.",
    MINUMIN_NUMBER_LENGTH: "يجب أن يكون طول الرقم المصغر 2 أي 91.",
    MAXIMUM_NUMBER_LENGTH: "يجب أن يكون الحد الأقصى لطول الرقم 4، أي +971",
    NUMBER_IS_REQUIRED: "الرقم مطلوب.",
    PLEASE_ENTER_VALID_NUMBER: "الرجاء إدخال رقم صحيح.",
    MOBILE_NUMBER_SHOULD_CONTAIN_MINIMUM_7_DIGITS:
      "يجب أن يحتوي رقم الهاتف المحمول على 7 أرقام كحد أدنى.",
    DOB_IS_REQUIRED: "DOB مطلوب.",
    GENDER_IS_REQUIRED: "الجنس مطلوب.",
    COUNTRY_IS_REQUIRED: "البلد مطلوب.",
    PLEASE_ENTER_VALID_COUNTRY: "الرجاء إدخال بلد صالح.",
    CITY_IS_REQUIRED: "المدينة مطلوبة.",
    PLEASE_ENTER_VALID_CITY: "الرجاء إدخال مدينة صالحة.",
    OCCUPATION_IS_REQUIRED: "المهنة مطلوبة.",
    PLEASE_ENTER_VALID_OCCUPATION: "يُرجى إدخال مهنة صالحة.",
    IMAGE_IS_REQUIRED: "الصورة مطلوبة.",
    OFFICIAL_EMAIL_IS_REQUIRED: "مطلوب عنوان بريد إلكتروني رسمي.",
    PLEASE_ENTER_VALID_QUALIFICATION: "يُرجى إدخال مؤهلات صالحة.",
    QUALIFICATION_IS_REQUIRED: "المؤهلات المطلوبة.",
    PERSONAL_PHOTO_IS_REQUIRED: "مطلوب صورة شخصية.",
    FILE_SIZE_IS_TOO_LARGE: "حجم الملف كبير جداً.",
    IDENTIFICATION_CARD_IS_REQUIRED: "بطاقة الهوية مطلوبة.",
    RESUME_IS_REQUIRED: "السيرة الذاتية مطلوبة.",
    DEPARTMENT_IS_REQUIRED: "القسم مطلوب.",
    PLEASE_ENTER_VALID_DEPARTMENT: "الرجاء إدخال قسم صالح.",
    MAJOR_IS_REQUIRED: "التخصص مطلوب.",
    PLEASE_ENTER_VALID_MAJOR: "يُرجى إدخال تخصص صحيح.",
    EXPERIENCE_IS_REQUIRED: "الخبرة مطلوبة.",
    BIOGRAPHY_IS_REQUIRED: "السيرة الذاتية مطلوبة.",

    ///////
    SIGN_UP_FOR_AN_ACCOUNT: "قم بتسجيل الدخول إلى حسابك",
    FIRST_NAME: "الاسم الأول",
    LAST_NAME: "اسم العائلة",
    EMAIL: "بريد إلكتروني*",
    PHONE_NUMBER: "رقم التليفون*",
    CODE: "الكود*",
    CONFIRM_PASSWORD: "تأكيد كلمة المرور",
    DATE_OF_BIRTH: "تاريخ الميلاد*",
    GENDER: "نوع الجنس* ",
    SELECT_GENDER: "--اختر الجنس--",
    MALE: "الذكور",
    FEMALE: "أنثى",
    COUNTRY: "دولة*",
    CITY: "مدينة*",
    MUMBAI: "مومباي",
    INDIA: "الهند",
    IDENTIFICATION_CARD: "بطاقة الهوية*",
    PASSPORT_NO_NATIONAL_RESIDENT_ID_LQAMA:
      "(رقم جواز السفر، بطاقة الهوية الوطنية وبطاقة الإقامة - الإقامة)*",
    UPLOAD_HERE: "تحميل هنا",
    OCCUPATION: "إشغال*",
    DEVELOPER: "المطور",
    OFFICIAL_EMAIL: "بريد إلكتروني*",
    QUALIFICATION: "مؤهل",
    TYPE_HERE: "اكتب هنا",
    PERSONAL_PHOTO: "الصورة الشخصية",
    RESUME: "سيرة ذاتية",
    DEPARTMENT: "قسم",
    MAJORS: "التخصص",
    EXPERIENCE: "خبرة",
    BIOGRAPHY: "سيرة*",
    /////////Forgot Password///////
    NO_WORRIES_WE_LL_SEND_YOU_RESET_INSTRUCTIONS:
      "لا تقلق، سوف نرسل لك تعليمات إعادة التعيين.",
    RESET_PASSWORD: "إعادة تعيين كلمة المرور",
    PASSWORD_RESET: "إعادة تعيين كلمة المرور",
    WE_SENT_A_CODE_TO_RAFFLES: "أرسلنا رمزاً إلى raffles@gmail.com",
    CONTINUE: "اكمل",
    CREATE_NEW_PASSWORD: "إنشاء كلمة مرور جديدة",
    MUST_BE_AT_LEAST_8_CHARACTERS: "يجب أن تكون 8 أحرف على الأقل.",
    ALL_SET_NOW: "كل شيء جاهز الآن!",
    YOUR_PASSWORD_HAS_BEEN_RESET_NOW:
      "لقد تم إعادة تعيين كلمة المرور الخاصة بك الآن.",
    ////////
    VERIFY_MOBILE_NUMBER: "التحقق من رقم الجوال",
    ENTER_THE_CODE_JUST_SENT_TO: "أدخل الرمز الذي تم إرساله للتو إلى",
    ENTER_CODE: "أدخل الرمز",
    DIDNT_GET_CODE: "لم تحصل على الرمز؟",
    SEND_AGAIN: "اعادة الارسال.",
    CALL_ME_INSTEAD: "اتصل بي بدلا من ذلك",
    VERIFY_MOBILE_NUMBER_BUTTON: "التحقق من رقم المحمول",
    TERMS_CONDITIONS: "الشروط والأحكام",
    AGREEMENTS: "الاتفاقيات",
    I_AGREE: "اوافق",
    DECLINE: "تخطى",
    ////////////////
    PRIVACY_POLICY: "سياسة الخصوصية",
    UPDATED_ON: "تم التحديث في 25 مايو 2023",
    UNDERSTOOD: "مفهوم",
    ///////LANDING PAGE///////////
    ABOUT_US: "نبذة عنا",
    COURSES: "الدورات",
    CONTACT_US: "من نحن",
    SIGN_IN: "تسجيل الدخول",
    CREATE_NEW_ACCOUNT: "إنشاء حساب جديد",
    EXPLORE: "استكشف",
    OUR_ONLINE_COURSES_TO: "دوراتنا التدريبية عبر الإنترنت إلى",
    ENHANCE: "تعزيز",
    YOUR: "الخاص بك",
    SKILLS: "المهارات",
    AND: "و",
    EXPERTISE: "الخبرة",
    DESIGN_YOUR_FUTURE_WITH_RAFFLES: "صمم مستقبلك مع رافلز.",
    GET_STARTED: "ابدأ الآن",
    SEE_HOW_IT_WORKS: "انظر كيف تعمل",
    PUBLIC_SPEAKING: "التحدث أمام الجمهور",
    CAREER_ORIENTED: "التوجّه الوظيفي",
    CREATIVE_THINKING: "التفكير الإبداعي",
    STUDENTS_LEARNING: "الطلاب يتعلمون",
    ONLINE_COURSES: "الدورات التدريبية عبر الإنترنت",
    TUTORS: "المعلمون",
    VIDEOS: "مقاطع الفيديو",
    TRUSTED_BY_TOP_100_COMPANIES: "موثوق بها من قِبل أكثر من 100+ شركة",
    EXPLORE_PROGRAMS: "استكشف البرامج",
    OUR_MOST_POPULAR_CLASSES: "دروسنا الأكثر شعبية",
    BROWSE_COURSES: "تصفح الدورات التدريبية",
    RAFFLES_EDUCATION_INSTITUTE_ETC: `تأسس معهد رافلز التعليمي في عام 2014، وهو واحد من
                    سلسلة من ثلاثين فرعًا لمعاهد رافلز التي تأسست في
                    تأسست عام 1990 في سنغافورة، وهي واحدة من أوائل
                    المؤسسات الخاصة التي تقدم دورات في تصميم الأزياء.`,
    WITHIN_5_YEARS_ETC: `في غضون 5 سنوات، تم تقديم تخصصات متعددة في رافلز
                    المعهد، بما في ذلك (التصميم الجرافيكي - تصميم المنتجات -
                    تصميم المجوهرات - التصميم الداخلي - تصميم الألعاب - تصميم الرسوم المتحركة).`,
    IN_2004_RAFFLES_DESIGN_ETC: `في عام 2004، حصل معهد رافلز للتصميم على جائزة
                    جاهزية سنغافورة لجودة التعليم الخاص
                    المنظمات. وفي عام 2005، حصل رافلز على جائزة كيس تراست
                    للتعليم، وفي عام 2008، حصل معهد رافلز للتصميم على جائزة
                    معترف به من قبل إدوبول كواحد من أفضل عشر مؤسسات تعليمية
                    المؤسسات التعليمية في سنغافورة وأكبر مزود
                    التعليم الخاص على مستوى العالم.`,
    OUR_SERVICES: "خدماتنا",
    DESIGN_YOUR_FUTURE_WITH_OUR_GLOBAL_NETWORK:
      "صمم مستقبلك مع شبكتنا العالمية من المؤسسات الحائزة على جوائز المؤسسات الحائزة على جوائز",
    USER_EXPERIENCE: "تجربة المستخدم",
    LESSONS_ON_DESIGN_THAT_COVER: "دروس في التصميم تغطي أحدث أحدث التطورات.",
    LEARN_MORE: "اعرف المزيد",
    MARKETING: "التسويق",
    MARKETING_COURSES_THAT_COVER:
      "دورات التسويق التي تغطي أحدث الاتجاهات التسويقية",
    WEB_DEVELOPMENT: "تطوير الويب",
    CLASSES_IN_DEVELOPMENT_THAT_COVER:
      "دروس في التطوير تغطي أحدث التطورات في الويب.",
    COURSES_WERE_EXCELLENT: `كانت الدورات ممتازة! للأفراد الذين يحتاجون إلى دورة تنشيطية
                    أو الذين يتطلعون لبدء مسيرة مهنية جديدة، فإن هذه هي
                    المنصة الرئيسية.`,
    RAFFLES_JR: "رافلز جونيور",
    STUDENT_DUBAI_NATIONAL_UNIVERSITY: "طالب، جامعة دبي الوطنية",
    TOP_LEARNING_EXPERIENCES_THAT_CREATE: "أفضل تجارب التعلم التي تخلق المزيد",
    MORE_TALENT_IN_THE_WORLD: "من المواهب في العالم.",
    EASTERN_TAM_KEEN_ALL_RIGHTS_RESERVED:
      "2023 الشرقية تام الشرقية حريص. جميع الحقوق محفوظة.",
    //Lecturer
    "Create New Course": "إنشاء دورة جديدة",
    Analytics: "تحليلات",
    Edit: "تعديل",
    "Schedule Live Sessions": "جدولة الجلسات المباشرة",
    "Scheduled Session": "الجلسة المجدولة",
    "Schedule new Session": "جدولة جلسة جديدة",
    "Join Now": "انضم الآن",
    "No Live Session": "لا توجد جلسة مباشرة",
    "Create New Assessment": "إنشاء تقييم جديد",
    "No Assessment": "لا يوجد تقييم",
    Questions: "أسئلة",
    "Create Assessment": "إنشاء تقييم",
    "Course Name": "اسم الدورة",
    "Assessment Name": "اسم التقييم",
    "Number of Retake Test": "عدد اختبارات الإعادة",
    "Passing Score": "درجة النجاح* (ضمن 100%)",
    "Select Course": "اختر الدورة",
    Marks: "الدرجات",
    "Max Marks" : "الحد الأقصى للدرجات",
    "Student Answer" : "إجابة الطالب",
    "Multiple Choice Answer": "إجابة متعددة الاختيارات",
    "Single Choice Answer": "إجابة اختيار واحد",
    "DropDown Answer": "إجابة منسدلة",
    "Subjective Answer": "إجابة موضوعية",
    "Correct Answer": "الإجابة الصحيحة",
    "Add Questions": "أضف أسئلة",
    "Submit Assessment": "تقديم التقييم",
    "Assessment test results": "نتائج اختبار التقييم",
    Test: "اختبار",
    "Test Date": "تاريخ الاختبار",
    Range: "نطاق",
    "S No": "رقم التسلسل",
    "Submit Result": "تقديم النتيجة",
    "Official Email": "البريد الإلكتروني الرسمي",
    "New Password": "كلمة مرور جديدة",
    "Old Password": "كلمة المرور القديمة",
    "Confirm Password": "تأكيد كلمة المرور",
    "Password Changed": "تم تغيير كلمة المرور",
    "Your password has been successfully changed":
      "تم تغيير كلمة المرور الخاصة بك بنجاح.",
    "Email notification": "إشعار البريد الإلكتروني",
    enabled: "ممكّن",
    disabled: "معطل",
    "Back to Home": "العودة إلى الصفحة الرئيسية",
    "Live Sessions Overview": "نظرة عامة على الجلسات المباشرة",
    "Number of Sessions": "عدد الجلسات",
    "Date Range": "نطاق التاريخ",
    "Sessions Attended": "الجلسات المحضورة",
    "Student Name": "اسم الطالب",
    "New Users": "المستخدمين الجدد",
    "Regular Users": "المستخدمين المنتظمين",
    "Number of Students": "عدد الطلاب",
    Dates: "التواريخ",
    "Participation Analysis": "تحليل المشاركة",
    Attended: "حضره",
    Users: "المستخدمون",
    "Not Attended": "لم يحضر",
    "Participant Attendance": "حضور المشاركين",
    "Search for participant here": "ابحث عن المشاركين هنا",
    Date: "تاريخ",
    Time: "الوقت",
    "Course Banner/Image": "لافتة/صورة الدورة",
    Information: "معلومات",
    Title: "عنوان",
    "Course Category": "فئة الدورة",
    Description: "وصف",
    Price: "سعر",
    "Select Level": "اختر المستوى",
    "Course Type": "نوع الدورة",
    "No of Lessons": "عدد الدروس",
    "Total Duration of Lessons": "إجمالي مدة الدروس",
    "Lesson Video": "فيديو الدرس",
    "Lesson Attachments": "مرفقات الدرس",
    "Add Lesson": "أضف درسًا",
    "Save Course": "احفظ الدورة",
    "Course Delete": "حذف الدورة",
    "Are you sure want to delete this Course":
      "هل أنت متأكد أنك تريد حذف هذه الدورة؟",
    "Delete Course": "حذف الدورة",
    Placeholder: "عنصر نائب",
    "Type Price": "اكتب السعر",
    Type: "نوع",
    "Type Address here": "اكتب العنوان هنا",
    "Click to upload or drag & drop": "انقر للتحميل أو اسحب وأفلت",
    "Maximum file size 100 MB.": "الحد الأقصى لحجم الملف 100 ميجابايت.",
    uploaded: "تم الرفع",
    typeLessonName: "أدخل اسم الدرس",
    nameIsRequired: "الاسم مطلوب",
    titleIsRequired: "العنوان مطلوب.",
    pleaseSelectCourseCategory: "يرجى تحديد فئة الدورة.",
    pleaseSelectCourseType: "يرجى تحديد نوع الدورة.",
    pleaseSelectLevel: "يرجى تحديد المستوى.",
    pleaseSelectLanguage: "يرجى تحديد اللغة.",
    informationRequired: "المعلومات مطلوبة.",
    courseBannerRequired: "صورة الدورة مطلوبة.",
    descriptionRequired: "الوصف مطلوب.",
    validPriceRequired: "يرجى إدخال سعر صالح.",
    noOfLessonsRequired: "عدد الدروس مطلوب.",
    validNumberRequired: "يرجى إدخال رقم صالح.",
    validDuration: "يرجى إدخال مدة صالحة.",
    requiredDuration: "يجب إدخال عدد المدة.",
    requiredVideo: "الفيديو مطلوب.",
    requiredAttachments: "المرفقات مطلوبة.",
    requiredDescription: "الوصف مطلوب.",
    createCourse: "إنشاء دورة",
    selectCourseCategory: "اختر فئة الدورة",
    uploadCourse: "رفع الدورة",
    uploadLessonVideos:
      "قم بتحميل مقاطع فيديو الدروس من مدير الملفات الخاص بك لعرض التعرف على الوجه",
    upload: "تحميل",
    scheduleSession: "جدولة الجلسة",
    subject: "الموضوع",
    course: "الدورة",
    to: "إلى",
    showLiveLocationAddress: "عرض عنوان الموقع المباشر",
    showToStudent: "عرض على الطالب",
    saveSession: "حفظ الجلسة",
    Delete: "حذف",
    sessionDelete: "حذف الجلسة",
    confirmSessionDelete: "هل أنت متأكد من أنك تريد حذف هذه الجلسة.",
    deleteSession: "حذف الجلسة",
    dateRequired: "التاريخ مطلوب",
    dateInFuture: "يجب أن يكون التاريخ في المستقبل.",
    startTimeRequired: "الوقت المبدئي مطلوب.",
    subjectRequired: "الموضوع مطلوب.",
    locationRequired: "الموقع مطلوب.",
    courseRequired: "الدورة مطلوبة.",
    endTimeRequired: "الوقت النهائي مطلوب.",
    endTimeGreaterThanStart: "يجب أن يكون الوقت النهائي أكبر من وقت البدء.",
    "Edit Assessment": "تحرير التقييم",
    saveAssessment: "حفظ التقييم",
    assessmentDelete: "حذف التقييم",
    confirmDeleteAssessment: "هل أنت متأكد أنك تريد حذف هذا التقييم؟",
    courseNameRequired: "اسم الدورة مطلوب.",
    scoreRequired: "الدرجة مطلوبة.",
    passingScoreValidation: "يجب أن تكون الدرجة النجاح أقل من 100.",
    retakeTestRequired: "إعادة الاختبار مطلوبة.",
    marksRequired: "العلامات مطلوبة.",
    textRequired: "النص مطلوب.",
    correctAnswerRequired: "الإجابة الصحيحة مطلوبة.",
    correctAnswerInvalid: "هذه الإجابة الصحيحة غير صالحة لأنها لا تتطابق مع أي من الخيارات المقدمة أعلاه.",
    correctAnswerMatch: "يجب أن تتطابق الإجابة الصحيحة تمامًا مع أي من خيارات الإجابة المقدمة.",
    answerRequired: "الإجابة مطلوبة.",
    addFourOptions: "يرجى إضافة أربعة خيارات إجابة مختلفة.",
    live: "مباشر",
    Upcoming: "قادمة", 
    Duration: "مدة",
    dollar: "دولار",
    hour : "ساعة",
    min : "دقيقة",
    Enrolled : "التحق",
    "Assessment submitted successfully" : "تم تقديم التقييم بنجاح",
    "Results will be available after manual grading by the lecturer" : "ستكون النتائج متاحة بعد التصحيح اليدوي من قبل المحاضر",
    "Assessment submitted. Results will be available after manual grading by the lecturer" : "تم تقديم التقييم، وسوف تكون النتائج متاحة بعد التصحيح اليدوي من قبل المحاضر",
  },
};
// {i18nJs.t('Browse')}
// i18nJs.t('Browse')

i18nJs.locale = "en";
// i18nJs.locale = "ar";

i18nJs.missingTranslation = (key: any) => {
  return "";
};

export default i18nJs;
