import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { Stripe, loadStripe, StripeElements } from '@stripe/stripe-js';
import { CardNumberElement} from '@stripe/react-stripe-js';
import toast  from "react-hot-toast";
import i18nJs from "../../../components/src/TranslateLanguage";

export const stripePromise = loadStripe('pk_test_51NdoyzSC3bsdjRmpsNo3KFqXf89VTuRMe9ZFUwo2yEOglS899DQeSIBM3HZySpHWk1cABQn2yIB4YTxGxghBhxWD006TeY2L3k');
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  created_at: string;
  id: string;
  description: string;
  is_accepted: boolean;
}

// Customizable Area End
export interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  termsCondsList: ITermsConds[];
  getDisbled:boolean;
  isTermsCondsAccepted: boolean;
  termsCondsData: any;
  value:any;
  token: string;
  uploaded_Payment_documents:any;
  uploaded_Payment_documents1:any;
  accountId: number;
  fileName:any;
  isAdminUser: boolean;
  toggleTabpan: string;
  termsConds: ITermsConds | null;
  initialvalue : {
    cardNumber: any;
    holderName: string;
    date:any;
    CVV:any;
  };
  paymenttModalVisible: boolean;
  openSuccessDialog: boolean;
  uploadProgress_uploaded_Payment_documents:any;
  uploadProgress_uploaded_Payment_documents1:any;
  paymentsDetails:any;
  paymentsBankDetails:any;
  flag:boolean;
  error:string | null;
  contactNo:string | null;
  userDetails: any | null;
  getCategoriesDataPayments: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signuplecutureApiCallId1: string = ""
  getTermsCondsCallId: string = "";
  getAccountGroupsCallId: string = "";
  getTermsCondsListCallId: string = "";
  signupApiCallId1: string = "";
  getTermsConditionDataCallId : string = "";
  setAcceptanceOfTermsCondsId: string = "";
  getpaymentsDataAPICallId: any;
  getpaymentsBankDetailsAPICallId: any;
  uploadPaymentReceiptAPICallId: any;
  stripe: Stripe | null;
  elements: StripeElements | null;
  tabbyPaymentReceiptAPICallId: string | null;
  tabbyWebUrl: string;
  getProfileDetailsCallId:any;
  getCategoriesForPaymentsApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accountId: -1,
      fileName:{},
      termsCondsList: [],
      isAdminUser: true,
      uploaded_Payment_documents:{},
      uploaded_Payment_documents1:{},
      isTermsCondsAccepted: false,
      termsCondsData: [],
      value:{},
      isLoading: false,
      token: "",
      getDisbled:false,
      toggleTabpan: "payoffline",
      termsConds: null,
      initialvalue: {
        cardNumber: "",
        holderName: "",
        date: '',
        CVV: ''
      },
      paymenttModalVisible: false,
      openSuccessDialog: true,
      uploadProgress_uploaded_Payment_documents:"0",
      uploadProgress_uploaded_Payment_documents1:"0",
      paymentsDetails:{},
      paymentsBankDetails:'',
      flag:true,
      error:null,
      contactNo: null,
      userDetails: {},
      getCategoriesDataPayments: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.stripe = null;
    this.elements = null;
    this.tabbyPaymentReceiptAPICallId = null;
    this.tabbyWebUrl = '';
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    let token = await getStorageData('token');
    this.setState({ token: token }, () => {
      this.getPaymentsData();
      this.getCategoriesForPayments();
    });
    await this.getBankDetailsData();
    this.outputSame();
    this.getProfileDetails();

    stripePromise.then((stripe) => {
      this.stripe = stripe;
    });
    let contact = await getStorageData("contact_no");
    this.setState({contactNo:contact});
}

tabbyPayment = async() => {
  const header = {
    token: this.state.token,
    'Content-Type': configJSON.apiContentType, 
  };

let merchantUrl = window.location.host;
  let data = {
  "account_id": localStorage.getItem("user_id"),
  "amount": this.state.paymentsDetails.data.attributes.price,
  "course_id": this.state.paymentsDetails.data.id,
  "currency": "SAR",
  "description": this.state.paymentsDetails.data.attributes.information,
  "email": this.state.userDetails.data.attributes.email,
  "name": this.state.userDetails.data.attributes.firts_name,
  "phone": this.state.userDetails.data.attributes.phone_number,
  "title": this.state.paymentsDetails.data.attributes.course_name,
  "merchant_urls": {
    "success": merchantUrl + "/Categoriessubcategories/TabbyPaymentSuccess",
    "cancel": merchantUrl + "/Categoriessubcategories/TabbyPaymentCanceled",
    "failure": merchantUrl + "/Categoriessubcategories/TabbyPaymentFailure"
}
}

  const getProfileIdRequest: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.tabbyPaymentReceiptAPICallId = getProfileIdRequest.messageId;
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.tabbyPaymentCheckout
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.createPaymentMethodMethod
  );

  runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
}
 
  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const stripe = this.stripe;
    const elements = this.elements;
  
    if (stripe && elements) {
      const cardElement = elements.getElement(CardNumberElement);
  
      if (!cardElement) {
        this.setState({ error: 'One or more card elements not found' });
        return;
      }
    } 
  };
  
  handleSetElements = (elements: StripeElements | null) => {
    this.elements = elements;
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };


handleFileChange = (event: any) => {
  
  if(event.target.files[0]){
    const Filename = event.target.files[0]; 
    const stateName = event.target.name
    const uploadTime = Date.now();
    this.setState({ ...this.state,[stateName]: Filename , flag : false });
    this.startUpload(stateName,uploadTime,Filename);
  }
};

getProfileDetails = async() => {
  const UserId = await getStorageData('user_id')
  
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getProfileDetailsCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.allProfileEndPoint + UserId
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPaymentMethodsMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

startUpload = (stateName:string, uploadTime : any, Filename:any) => {
  const intervalId = setInterval(() => {
    const elapsedTime = Date.now() - uploadTime;
    const uploadProgress = Math.min(Math.floor((elapsedTime / 5000) * 100), 100);

    this.setState({...this.state, [`uploadProgress_${stateName}`]: uploadProgress });

      uploadProgress >= 100 && clearInterval(intervalId);
      uploadProgress >= 100 && this.uploadComplete(stateName, Filename); 
  }, 10);
};

uploadComplete = (stateName:string, Filename:any) => {
  this.setState({...this.state,[stateName]: Filename });
};


ternaryCondition = (condition: boolean, trueStmt: any, falseStmt: any) => {
  if (condition) {
    return trueStmt
  } else {
    return falseStmt
  }
}

getPaymentsData = () => {
  const getParam = this.props.navigation.getParam("id");
  const header = {
    "Content-Type": "application/json",
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getpaymentsDataAPICallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getPaymentData}?course_id=${getParam}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPaymentMethodsMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getBankDetailsData = async() => {

  const header = {
    "Content-Type": "application/json",
    token: this.state.token
  };

  const getProfileIdRequest: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getpaymentsBankDetailsAPICallId = getProfileIdRequest.messageId;
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getPaymentDetails
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPaymentMethodsMethod
  );

  runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
}

extractText = (htmlString:any) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

outputSame = () =>{
  const bankDetailsText = this.extractText(this.state.paymentsBankDetails);
}

uploadPaymentReceipt = async() => {

  if(this.state.uploaded_Payment_documents.name){
    const getParam = this.props.navigation.getParam("id");
  const header = {
    token: this.state.token,
    redirect: 'follow'
  };

  const formData = new FormData();
    formData.append("course_id", getParam)
    formData.append("payment_receipt", this.state.uploaded_Payment_documents)

  const getProfileIdRequest: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.uploadPaymentReceiptAPICallId = getProfileIdRequest.messageId;
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.uploadPaymentReceipt
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  getProfileIdRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.createPaymentMethodMethod
  );

  runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  } else {
    toast.error(i18nJs.t('Please attach the payment document before submitting'))
  }
}

uploadSuccess = () => {
  this.setState({ uploaded_Payment_documents : '' , paymenttModalVisible : true , uploadProgress_uploaded_Payment_documents : 0 });
}

getTabbyPayment = (responseJson: any) => {
  if(responseJson.configuration.available_products.installments) {
    this.tabbyWebUrl = responseJson.configuration.available_products.installments[0].web_url;
  }
localStorage.setItem('tabbyId', responseJson.id);
}

toggleTabbyTab = () => {
  this.setState({ toggleTabpan: "paybytabby"});
  this.tabbyPayment();
}

goToPaymentHistory = () => {
  this.props.navigation.navigate("GetPayments");
}

goToCategories = () => {
  this.props.navigation.navigate("Categoriessubcategories");
}

getPaymentInfo = (responseJson: object) => {
  this.setState({ paymentsDetails : responseJson });
  localStorage.setItem('paymentInformation', JSON.stringify(responseJson));
}

getProfileInfo = (responseJson: object) => {
  this.setState({userDetails: responseJson})
}

convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60 === 0 ? '' : `and ${minutes % 60} mins`;
  return `${hours} hr ${remainingMinutes}`;
}
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    (this.getpaymentsDataAPICallId == apiRequestCallId && responseJson) && this.getPaymentInfo(responseJson); 
    (this.getpaymentsBankDetailsAPICallId == apiRequestCallId && responseJson) && this.setState({ paymentsBankDetails : responseJson.data[0]?.bank_details });
    (this.uploadPaymentReceiptAPICallId == apiRequestCallId && responseJson) && this.uploadSuccess();
    (this.tabbyPaymentReceiptAPICallId == apiRequestCallId && responseJson) && this.getTabbyPayment(responseJson); 
    (this.getProfileDetailsCallId == apiRequestCallId && responseJson) && this.getProfileInfo(responseJson); 
    if(apiRequestCallId === this.getCategoriesForPaymentsApiCallId && responseJson?.data) {
      this.setState({getCategoriesDataPayments: responseJson.data})
    }
    // Customizable Area End
  }

  getCourseCategoryPayments = (category_id_payments: number) => {
    let category = this.state.getCategoriesDataPayments.map((item: any, index: number) => {
      if (Number(item.id) === category_id_payments) {
        return i18nJs.locale === 'ar' ? item.attributes.name_arb : item.attributes.name;
      }
    });
  
    return category;
  };

  getCategoriesForPayments = () => {
    
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesForPaymentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPointForpayments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPaymentMethodsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
