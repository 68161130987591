import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  LinearProgress,
  Divider,
  styled,
  Avatar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { Chart } from "react-google-charts";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { DateRangePicker } from "materialui-daterange-picker";
import i18nJs from "../../../components/src/TranslateLanguage";
import moment from "moment";
const baseURL = require("../../../framework/src/config.js").baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: "#589CBC",
      contrastText: "#37657F",
    },
  },
});

const progressBarStyle = {
  width: "100%",
  height: "5px",
  borderRadius: "10px",
  marginBottom: 4,
};

const barChartOptions = {
  chart: {
    title: "Live Sessions Overview",
  },
  hAxis: {
    title: i18nJs.t('Dates'),
    titleTextStyle: {
      color: "#64748B",
      fontFamily: "Corbel V2",
      fontName: "text-sm/font-bold",
      fontSize: 14,
      bold: true,
      italic: false,
    },
    textStyle: {
      fontSize: 14,
      color: "#475569",
      fontFamily: "Corbel V2",
      bold: false,
      italic: false,
    },
  },
  fontName: "Corbel V2",
  vAxis: {
    title: i18nJs.t('Number of Students'),
    format: "0",
    titleTextStyle: {
      color: "#64748B",
      fontFamily: "Corbel V2",
      fontName: "text-sm/font-bold",
      fontSize: 14,
      bold: true,
      italic: false,
    },
    textStyle: {
      fontSize: 14,
      color: "#475569",
      fontFamily: "Corbel V2",
      bold: false,
      italic: false,
    },
  },
  legend: "none",
  isStacked: true,
  bar: {
    groupWidth: "30%",
  },
  colors: ["#37657F", "#589CBC"],
};

const pieChartOptions = {
  chart: {
    title: i18nJs.t('Participation Analysis'),
  },
  legend: "none",
  colors: ["#37657F", "#589CBC"],
  tooltip: {
    text: "value",
  },
  pieSliceText: "none",
  backgroundColor: "transparent",
};

// Customizable Area End

import VisualAnalyticsChartController, {
  Props,
} from "./VisualAnalyticsChartController";



export default class VisualAnalyticsChart extends VisualAnalyticsChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { pieChartData } = this.state;

    const allZero = pieChartData
      .slice(1)
      .every((item: number[]) => item[1] === 0);

    const transformedData = this.state.attendanceData.attendance_data?.map((session : any) => ({
      name: session.first_name,     
      time: `${moment(session.time_from, "hh:mm A").format("hh:mm A")} to ${moment(session.time_to, "hh:mm A").format("hh:mm A")}`,
      date: this.formatDateForParticipantAttendance(session.session_date, false),
      attended: session.attended.toLocaleUpperCase(),
    }));

    const filteredData = transformedData?.filter((item: any) =>
      item && item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
  );

    return (
      // Customizable Area Start
      <MainContainer>
        <ThemeProvider theme={theme}>
          <NavigationMenu
            id={this.props.id}
            navigation={this.props.navigation}
            title="Analytics"
          >
            <Paper elevation={0} className="mainPaper">
              <Box component="div" className="pageSize">
                <Box
                  onClick={this.navigateToHomePage}
                  data-test-id="navigate-to-home"
                  className="navigateButton"
                  dir="ltr"
                >
                  <svg
                    className="svgBack"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M13 19l-7-7 7-7" />
                    <path d="M6 12h15" />
                  </svg>
                  <Box className="navigateButtonText">{i18nJs.t('Back to Home')}</Box>
                </Box>

                <Grid container spacing={2} className="mainGrid">
                  <Grid item xs={12} lg={8} className="gridItemHeight99">
                    <Paper className="firstChartPaper">
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Box className="firstChartTitle">
                          {i18nJs.t('Live Sessions Overview')}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <IconButton
                            className="dateRange"
                            onClick={this.toggleDatePickerFirstChart}
                          >
                            <Box className="dateRangeText">
                              {this.state.setDateRangeForFirstChart
                                ? this.state.setDateRangeForFirstChart
                                : i18nJs.t('Date Range')}
                            </Box>
                            <CalendarTodayOutlinedIcon
                              style={{ color: "#37657F" }}
                            />
                          </IconButton>

                          <Dialog
                            open={this.state.isCalendarOpenFirstChart}
                            onClose={this.handleCloseFirstChart}
                            fullWidth={true}
                          >
                            <DialogTitle></DialogTitle>
                            <DateRangePicker
                              open={this.state.isCalendarOpenFirstChart}
                              toggle={this.toggleDatePickerFirstChart}
                              onChange={(range) => {
                                this.onSubmitFirstChartDate(range);
                                this.handleCloseFirstChart();
                              }}
                              definedRanges={[]}
                            />
                          </Dialog>
                        </Box>
                      </Box>

                      {this.state.firstChartData ? (
                        <Box>
                          <Box className="legendFirstChart">
                            <Box className="legendPosition">
                              <span
                                className="legendSpan"
                                style={{
                                  backgroundColor: "#37657F",
                                }}
                              ></span>
                              <span>{i18nJs.t('Regular Users')}</span>
                            </Box>
                            <Box className="legendPosition">
                              <span
                                className="legendSpan"
                                style={{
                                  backgroundColor: "#589CBC",
                                }}
                              ></span>
                              <span>{i18nJs.t('New Users')}</span>
                            </Box>
                          </Box>
                          <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={this.state.firstChartData}
                            options={barChartOptions}
                          />
                        </Box>
                      ) : (
                        <Typography
                          align="center"
                          variant="h6"
                          className="firstChartNoData"
                        >
                          {i18nJs.t('No data to display!')}
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid
                    spacing={1}
                    container
                    item
                    xs={12}
                    lg={4}
                    direction="column"
                    className="gridItemHeight100"
                  >
                    <Grid item xs className="secondChartGrid">
                      <Paper className="secondChartPaper">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mb={2}
                        >
                          <Box alignItems="center">
                            <Box className="rightChartTitle">
                              {`${i18nJs.t('Number of Sessions')} : ${this.state.userSessionData?.total_sessions}`}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <IconButton
                              className="dateRange"
                              onClick={this.toggleDatePickerUserSession}
                            >
                              <Box className="dateRangeText">
                                {this.state.setDateRangeForUserSession
                                  ? this.state.setDateRangeForUserSession
                                  : i18nJs.t('Date Range')}
                              </Box>
                              <CalendarTodayOutlinedIcon
                                style={{ color: "#37657F" }}
                              />
                            </IconButton>

                            <Dialog
                              open={this.state.isCalendarOpenUserSession}
                              onClose={this.handleCloseUserSession}
                              fullWidth={true}
                            >
                              <DialogTitle></DialogTitle>
                              <DateRangePicker
                                open={this.state.isCalendarOpenUserSession}
                                toggle={this.toggleDatePickerUserSession}
                                onChange={(range) => {
                                  this.onSubmitUserSessionDate(range);
                                  this.handleCloseUserSession();
                                }}
                                definedRanges={[]}
                              />
                            </Dialog>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Box className="studentNameText">{i18nJs.t('Student Name')}</Box>
                          <Box className="studentNameText">
                            {i18nJs.t('Sessions Attended')}
                          </Box>
                        </Box>
                        <Divider />
                        <Box>
                          {this.state.userSessionData.user_sessions?.length >
                          0 ? (
                            <>
                              {this.state.userSessionData.user_sessions?.map(
                                (student: any, index: any) => (
                                  <Box key={index}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Box className="studentProgressText">
                                        {`${student.first_name} ${student.last_name}`}
                                      </Box>
                                      <Box className="studentProgressText">
                                        {student.attended}
                                      </Box>
                                    </Box>
                                    <LinearProgress
                                      style={progressBarStyle}
                                      variant="determinate"
                                      value={Math.min(
                                        (student.attended_sessions /
                                          student.total_sessions) *
                                          100,
                                        100
                                      )}
                                    />
                                  </Box>
                                )
                              )}
                            </>
                          ) : (
                            <Typography
                              align="center"
                              variant="h6"
                              className="firstChartNoData"
                            >
                              {i18nJs.t('No data to display!')}
                            </Typography>
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs className="secondChartGrid">
                      <Paper className="secondChartPaper">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mb={2}
                        >
                          <Box alignItems="center">
                            <Box className="rightChartTitle">
                              {i18nJs.t('Participation Analysis')}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <IconButton
                              className="dateRange"
                              onClick={this.toggleDatePickerThirdChart}
                            >
                              <Box className="dateRangeText">
                                {this.state.setDateRangeForThirdChart
                                  ? this.state.setDateRangeForThirdChart
                                  : i18nJs.t('Date Range')}
                              </Box>
                              <CalendarTodayOutlinedIcon
                                style={{ color: "#37657F" }}
                              />
                            </IconButton>

                            <Dialog
                              open={this.state.isCalendarOpenThirdChart}
                              onClose={this.handleCloseThirdChart}
                              fullWidth={true}
                            >
                              <DialogTitle></DialogTitle>
                              <DateRangePicker
                                open={this.state.isCalendarOpenThirdChart}
                                toggle={this.toggleDatePickerThirdChart}
                                onChange={(range) => {
                                  this.onSubmitThirdChart(range);
                                  this.handleCloseThirdChart();
                                }}
                                definedRanges={[]}
                              />
                            </Dialog>
                          </Box>
                        </Box>
                        <Box flexGrow={1}>
                          {allZero ? (
                            <Typography
                              align="center"
                              variant="h6"
                              className="firstChartNoData"
                            >
                              {i18nJs.t('No data to display!')}
                            </Typography>
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <Box>
                                  <Chart
                                    chartType="PieChart"
                                    width="auto"
                                    height="auto"
                                    data={pieChartData}
                                    options={pieChartOptions}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4} alignContent="center">
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Box>
                                      <Box>
                                        <span
                                          className="legendIcon"
                                          style={{
                                            backgroundColor: "#1A587F",
                                          }}
                                        ></span>
                                        <span className="pieChartLegend">
                                          {i18nJs.t('Attended')}
                                        </span>
                                      </Box>
                                      <Box className="navigateButtonText">
                                        {
                                          this.state.pieChartNumberOfUsers
                                            .attended
                                        }{" "}
                                        {i18nJs.t('Users')}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      <Box>
                                        <span
                                          className="legendIcon"
                                          style={{
                                            backgroundColor: "#49BFD2",
                                          }}
                                        ></span>
                                        <span className="pieChartLegend">
                                          {" "}
                                         {i18nJs.t(' Not Attended')}
                                        </span>
                                      </Box>
                                      <Box className="navigateButtonText">
                                        {
                                          this.state.pieChartNumberOfUsers
                                            .notAttended
                                        }{" "}
                                        {i18nJs.t('Users')}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="gridItemHeight100">
                    <Paper className="thirdPaper">
                      <Box style={{ padding: "10px" }}>
                        <Box className="firstChartTitle thirdPaperPadding">
                          {i18nJs.t('Participant Attendance')}
                        </Box>

                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <Box className="home-search-block thirdPaperPadding">
                              <input
                                placeholder={i18nJs.t('Search for participant here')}
                                data-test-id="serchCourseValue-list"
                                onChange={this.handleSearchChange}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box className="thirdPaperDateRange">
                              <Box mb={2}>
                                <IconButton
                                  className="dateRange"
                                  onClick={
                                    this.toggleDatePickerParticipantAttendance
                                  }
                                >
                                  <Box className="dateRangeText">
                                    {this.state
                                      .setDateRangeForPartcipantAttendancet
                                      ? this.state
                                          .setDateRangeForPartcipantAttendancet
                                      : i18nJs.t('Date Range')}
                                  </Box>
                                  <CalendarTodayOutlinedIcon
                                    style={{ color: "#37657F" }}
                                  />
                                </IconButton>

                                <Dialog
                                  open={
                                    this.state
                                      .isCalendarOpenParticipantAttendance
                                  }
                                  onClose={
                                    this.handleCloseParticipantAttendance
                                  }
                                  fullWidth={true}
                                >
                                  <DialogTitle></DialogTitle>
                                  <DateRangePicker
                                    data-test-id="dateRangePicker"
                                    open={
                                      this.state
                                        .isCalendarOpenParticipantAttendance
                                    }
                                    toggle={
                                      this.toggleDatePickerParticipantAttendance
                                    }
                                    onChange={(range) => {
                                      this.onSubmitPartcipantAttendance(range);
                                      this.handleCloseParticipantAttendance();
                                    }}
                                    definedRanges={[]}
                                  />
                                </Dialog>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <TableContainer className="tableContainer">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableCellHeader">
                                  {i18nJs.t('Student Name')}
                                </TableCell>
                                <TableCell className="tableCellHeader">
                                  {i18nJs.t('Time')}
                                </TableCell>
                                <TableCell className="tableCellHeader">
                                  {i18nJs.t('Date')}
                                </TableCell>
                                <TableCell className="tableCellHeader alignEnd">
                                  {i18nJs.t('Attended')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData?.map(
                                (
                                  item: {
                                    name: string;
                                    time: string;
                                    date: string;
                                    attended: string;
                                  },
                                  index: React.Key | undefined
                                ) => (
                                  <TableRow
                                    key={index}
                                    className="tableRowBackground"
                                  >
                                    <TableCell className="tableCellBorder">
                                      <Box className="tableCellText">
                                        <Avatar
                                          className="avatar"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Box className="participantName">
                                          {item.name}
                                        </Box>
                                      </Box>
                                    </TableCell>
                                    <TableCell className="tableCell">
                                      {item.time}
                                    </TableCell>
                                    <TableCell className="tableCell">
                                      {item.date}
                                    </TableCell>
                                    <TableCell className="tableCell">
                                      {item.attended}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </NavigationMenu>
        </ThemeProvider>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .legendFirstChart": {
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  "& .legendPosition": {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
  },
  "& .legendSpan": {
    display: "inline-block",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  "& .mainPaper": {
    width: "100%",
    overflow: "auto",
  },
  "& .tableRowBackground:hover": {
    background: "#E2E8F0",
  },
  "& .pageSize": {
    padding: "0px 36px 36px 36px",
    background: "#F6F8FA",
    height: "calc(100vh - 150px)",
  },
  "& .navigateButton": {
    display: "flex",
    alignItems: "center",
    color: "#37657F",
    cursor: "pointer",
    width: "fit-content",
  },
  "& .svgBack": {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
  },
  "& .navigateButtonText": {
    fontFamily: "Corbel V2",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& .mainGrid": {
    height: "100%",
    overflow: "auto",
    padding: "8px",
    background: "#E2E8F0",
    marginTop: "10px",
    borderRadius: "16px",
  },
  "& .gridItemHeight100": {
    height: "100%",
  },
  "& .gridItemHeight99": {
    height: "99%",
  },
  "& .firstChartPaper": {
    padding: 16,
    height: "100%",
    borderRadius: "16px",
    overflow: "auto",
  },
  "& .firstChartTitle": {
    color: "#64748B",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  "& .firstChartNoData": {
    color: "#64748B",
    marginTop: "50px",
  },
  "& .secondChartGrid": {
    maxHeight: "50%",
  },
  "& .secondChartPaper": {
    padding: 16,
    borderRadius: "16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  "& .rightChartTitle": {
    color: "#64748B",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
  },
  "& .studentNameText": {
    color: "#64748B",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  "& .pieChartLegend": {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .legendIcon": {
    display: "inline-block",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  "& .dateRange": {
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "7px",
  },
  "& .studentProgressText": {
    marginBottom: 4,
    color: "#0F172A",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .thirdPaper": {
    borderRadius: "16px",
    margin: "20px auto",
  },
  "& .thirdPaperPadding": {
    padding: "16px",
  },
  "& .thirdPaperDateRange": {
    padding: "16px",
    textAlign: "end",
    marginTop: "6px",
    overflow: "auto",
  },
  "& .thirdPaperDateRangeText": {
    color: "#64748B",
    marginLeft: 8,
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .dateRangeText": {
    color: "#64748B",
  },
  "& .tableContainer": {
    height: "50vh",
  },
  "& .tableCellBorder": {
    borderBottom: "none",
    padding: "12px",
  },
  "& .tableCellText": {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.32px",
  },
  "& .tableCellHeader": {
    borderBottom: "1px solid #E2E8F0",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Corbel V2",
    width: "30%",
  },
  "& .alignEnd": {
    width: "10%",
  },
  "& .tableCell": {
    borderBottom: "none",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.32px",
    fontFamily: "Corbel V2",
  },
  "& .avatar": {
    marginRight: "10px",
  },
  "& .participantName": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.32px",
    fontFamily: "Corbel V2",
  },
  "& .time-field": {
    display: "flex",
    alignItems: "center",
    gap: 4,
    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },
  "& .position-reletive": {
    position: "relative",
  },
  "& .w-100": {
    width: "100%",
  },
  "& .input-fiels-create": {
    border: "1px solid #CBD5E1",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    minWidth: "auto",
    fontSize: "11px",
    minHeight: "20px",
    "&::placeholder": {
      color: "#334155",
      fontFamily: "Corbel V2",
    },
  },
  "& .error-message": {
    fontFamily: "Corbel V2",
    color: "#FF0000",
  },
  "& .dateRange .MuiIconButton-label": {
    gap: "7px"
  },
});
// Customizable Area End
